<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="栏目管理"
      class="px-5 py-3 mb-5"
    >
      <div class="my-5">
        <v-btn
          color="success"
          @click="createColumnBtn"
        >
          <v-icon left>
            fa fa-plus
          </v-icon>
          创建栏目
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th>#</th>
            <th>名称</th>
            <th>ID</th>
            <th>父级栏目</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(column, index) in columnList"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{ column.name }}
              <v-btn
                v-copy="column.name"
                icon
                small
                dark
                color="#ccc"
              >
                <v-icon
                  x-small
                >
                  fa fa-clipboard
                </v-icon>
              </v-btn>
            </td>
            <td>
              {{ column.id }}
              <v-btn
                v-copy="column.id"
                icon
                small
                dark
                color="#ccc"
              >
                <v-icon
                  x-small
                >
                  fa fa-clipboard
                </v-icon>
              </v-btn>
            </td>
            <td>{{ column.parentId }}</td>
            <td class="text-right">
              <v-btn
                class="mx-2"
                small
                color="success"
                @click="putColumnBtn(index)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="error"
                @click="delColumn(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-trash-o
                </v-icon>
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="6"
        />
      </div>
    </base-material-card>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">创建栏目</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="name"
                  label="栏目名称"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="parentId"
                  :items="columnList"
                  label="父级栏目"
                  item-text="name"
                  item-value="id"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="weight"
                  label="排序"
                  hint="排序数值越大越靠后"
                  persistent-hint
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="summary"
                  name="input-7-4"
                  label="栏目简介"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="dialog = false"
          >
            关闭
          </v-btn>
          <v-btn
            v-show="operation === 'create'"
            color="success"
            @click="createColumn"
          >
            保存
          </v-btn>
          <v-btn
            v-show="operation === 'edit'"
            color="success"
            @click="putColumn"
          >
            修改
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        dialog: false,
        code: null,
        model: 'article',
        name: null,
        parentId: null,
        summary: null,
        weight: null,
        operation: 'create',
        columnList: [],
        columnId: null,
      }
    },
    created () {
      this.getColumn()
    },
    methods: {
      createColumnBtn () {
        this.operation = 'create'
        this.code = null
        this.model = 'article'
        this.name = null
        this.parentId = null
        this.summary = null
        this.weight = null
        this.dialog = true
      },
      // 创建栏目
      createColumn () {
        this.$axios.post('/categories', {
          // code: this.code !== '' ? this.code : null,
          model: 'article',
          name: this.name !== '' ? this.name : null,
          parentId: this.parentId !== '' ? this.parentId : null,
          summary: this.summary !== '' ? this.summary : null,
          weight: this.weight !== '' ? this.weight : null,
        })
          .then(res => {
            this.getColumn()
            this.$swal({
              title: '创建成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            console.log(err)
            this.$swal({
              title: '创建失败',
              icon: 'error',
              text: '原因:' + err,
              confirmButtonText: '知道了',
            })
          })
      },
      // 删除栏目
      delColumn (index) {
        this.$axios.delete(`/categories/${this.columnList[index].id}`)
          .then(res => {
            this.columnList.splice(index, 1)
            this.$swal({
              title: '删除成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            console.log(err)
            this.$swal({
              title: '删除失败',
              icon: 'error',
              text: '原因:' + err,
              confirmButtonText: '知道了',
            })
          })
      },
      // 编辑栏目
      putColumnBtn (index) {
        this.operation = 'edit'
        this.dialog = true
        this.code = this.columnList[index].code
        this.model = 'article'
        this.name = this.columnList[index].name
        this.parentId = this.columnList[index].parentId
        this.summary = this.columnList[index].summary
        this.weight = this.columnList[index].weight
        this.columnId = this.columnList[index].id
      },
      // 编辑栏目
      putColumn (index) {
        this.$axios.put(`/categories/${this.columnId}`, {
          code: this.code !== '' ? this.code : null,
          model: 'article',
          name: this.name !== '' ? this.name : null,
          parentId: this.parentId !== '' ? this.parentId : null,
          summary: this.summary !== '' ? this.summary : null,
          weight: this.weight !== '' ? this.weight : null,
        })
          .then(res => {
            this.getColumn()
            this.dialog = false
            this.$swal({
              title: '修改成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            console.log(err)
            this.$swal({
              title: '修改失败',
              icon: 'error',
              text: '原因:' + err,
              confirmButtonText: '知道了',
            })
          })
      },
      // 获取栏目
      getColumn () {
        this.$axios.get('/categories/article')
          .then(res => {
            this.columnList = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
</style>
